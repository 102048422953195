// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bengali-js": () => import("./../../../src/pages/bengali.js" /* webpackChunkName: "component---src-pages-bengali-js" */),
  "component---src-pages-carromninja-js": () => import("./../../../src/pages/carromninja.js" /* webpackChunkName: "component---src-pages-carromninja-js" */),
  "component---src-pages-gold-silver-campaign-js": () => import("./../../../src/pages/gold-silver-campaign.js" /* webpackChunkName: "component---src-pages-gold-silver-campaign-js" */),
  "component---src-pages-gujarati-js": () => import("./../../../src/pages/gujarati.js" /* webpackChunkName: "component---src-pages-gujarati-js" */),
  "component---src-pages-h-playstore-js": () => import("./../../../src/pages/h-playstore.js" /* webpackChunkName: "component---src-pages-h-playstore-js" */),
  "component---src-pages-hotstar-1-js": () => import("./../../../src/pages/hotstar1.js" /* webpackChunkName: "component---src-pages-hotstar-1-js" */),
  "component---src-pages-hotstar-js": () => import("./../../../src/pages/hotstar.js" /* webpackChunkName: "component---src-pages-hotstar-js" */),
  "component---src-pages-howtoplay-js": () => import("./../../../src/pages/howtoplay.js" /* webpackChunkName: "component---src-pages-howtoplay-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kannada-js": () => import("./../../../src/pages/kannada.js" /* webpackChunkName: "component---src-pages-kannada-js" */),
  "component---src-pages-ls-1-js": () => import("./../../../src/pages/ls1.js" /* webpackChunkName: "component---src-pages-ls-1-js" */),
  "component---src-pages-ls-10-js": () => import("./../../../src/pages/ls10.js" /* webpackChunkName: "component---src-pages-ls-10-js" */),
  "component---src-pages-ls-11-js": () => import("./../../../src/pages/ls11.js" /* webpackChunkName: "component---src-pages-ls-11-js" */),
  "component---src-pages-ls-12-js": () => import("./../../../src/pages/ls12.js" /* webpackChunkName: "component---src-pages-ls-12-js" */),
  "component---src-pages-ls-13-js": () => import("./../../../src/pages/ls13.js" /* webpackChunkName: "component---src-pages-ls-13-js" */),
  "component---src-pages-ls-14-bengali-js": () => import("./../../../src/pages/ls14-bengali.js" /* webpackChunkName: "component---src-pages-ls-14-bengali-js" */),
  "component---src-pages-ls-14-bhojpuri-js": () => import("./../../../src/pages/ls14-bhojpuri.js" /* webpackChunkName: "component---src-pages-ls-14-bhojpuri-js" */),
  "component---src-pages-ls-14-fb-bengali-js": () => import("./../../../src/pages/ls14-fb-bengali.js" /* webpackChunkName: "component---src-pages-ls-14-fb-bengali-js" */),
  "component---src-pages-ls-14-fb-bhojpuri-js": () => import("./../../../src/pages/ls14-fb-bhojpuri.js" /* webpackChunkName: "component---src-pages-ls-14-fb-bhojpuri-js" */),
  "component---src-pages-ls-14-fb-gujarati-js": () => import("./../../../src/pages/ls14-fb-gujarati.js" /* webpackChunkName: "component---src-pages-ls-14-fb-gujarati-js" */),
  "component---src-pages-ls-14-fb-hindi-js": () => import("./../../../src/pages/ls14-fb-hindi.js" /* webpackChunkName: "component---src-pages-ls-14-fb-hindi-js" */),
  "component---src-pages-ls-14-fb-kannada-js": () => import("./../../../src/pages/ls14-fb-kannada.js" /* webpackChunkName: "component---src-pages-ls-14-fb-kannada-js" */),
  "component---src-pages-ls-14-fb-malayalam-js": () => import("./../../../src/pages/ls14-fb-malayalam.js" /* webpackChunkName: "component---src-pages-ls-14-fb-malayalam-js" */),
  "component---src-pages-ls-14-fb-marathi-js": () => import("./../../../src/pages/ls14-fb-marathi.js" /* webpackChunkName: "component---src-pages-ls-14-fb-marathi-js" */),
  "component---src-pages-ls-14-fb-oriya-js": () => import("./../../../src/pages/ls14-fb-oriya.js" /* webpackChunkName: "component---src-pages-ls-14-fb-oriya-js" */),
  "component---src-pages-ls-14-fb-punjabi-js": () => import("./../../../src/pages/ls14-fb-punjabi.js" /* webpackChunkName: "component---src-pages-ls-14-fb-punjabi-js" */),
  "component---src-pages-ls-14-fb-tamil-js": () => import("./../../../src/pages/ls14-fb-tamil.js" /* webpackChunkName: "component---src-pages-ls-14-fb-tamil-js" */),
  "component---src-pages-ls-14-gujarati-js": () => import("./../../../src/pages/ls14-gujarati.js" /* webpackChunkName: "component---src-pages-ls-14-gujarati-js" */),
  "component---src-pages-ls-14-hindi-js": () => import("./../../../src/pages/ls14-hindi.js" /* webpackChunkName: "component---src-pages-ls-14-hindi-js" */),
  "component---src-pages-ls-14-js": () => import("./../../../src/pages/ls14.js" /* webpackChunkName: "component---src-pages-ls-14-js" */),
  "component---src-pages-ls-14-kannada-js": () => import("./../../../src/pages/ls14-kannada.js" /* webpackChunkName: "component---src-pages-ls-14-kannada-js" */),
  "component---src-pages-ls-14-malayalam-js": () => import("./../../../src/pages/ls14-malayalam.js" /* webpackChunkName: "component---src-pages-ls-14-malayalam-js" */),
  "component---src-pages-ls-14-marathi-js": () => import("./../../../src/pages/ls14-marathi.js" /* webpackChunkName: "component---src-pages-ls-14-marathi-js" */),
  "component---src-pages-ls-14-oriya-js": () => import("./../../../src/pages/ls14-oriya.js" /* webpackChunkName: "component---src-pages-ls-14-oriya-js" */),
  "component---src-pages-ls-14-punjabi-js": () => import("./../../../src/pages/ls14-punjabi.js" /* webpackChunkName: "component---src-pages-ls-14-punjabi-js" */),
  "component---src-pages-ls-14-tamil-js": () => import("./../../../src/pages/ls14-tamil.js" /* webpackChunkName: "component---src-pages-ls-14-tamil-js" */),
  "component---src-pages-ls-15-js": () => import("./../../../src/pages/ls15.js" /* webpackChunkName: "component---src-pages-ls-15-js" */),
  "component---src-pages-ls-16-js": () => import("./../../../src/pages/ls16.js" /* webpackChunkName: "component---src-pages-ls-16-js" */),
  "component---src-pages-ls-17-js": () => import("./../../../src/pages/ls17.js" /* webpackChunkName: "component---src-pages-ls-17-js" */),
  "component---src-pages-ls-2-js": () => import("./../../../src/pages/ls2.js" /* webpackChunkName: "component---src-pages-ls-2-js" */),
  "component---src-pages-ls-3-js": () => import("./../../../src/pages/ls3.js" /* webpackChunkName: "component---src-pages-ls-3-js" */),
  "component---src-pages-ls-4-hindi-js": () => import("./../../../src/pages/ls4hindi.js" /* webpackChunkName: "component---src-pages-ls-4-hindi-js" */),
  "component---src-pages-ls-4-js": () => import("./../../../src/pages/ls4.js" /* webpackChunkName: "component---src-pages-ls-4-js" */),
  "component---src-pages-ls-5-js": () => import("./../../../src/pages/ls5.js" /* webpackChunkName: "component---src-pages-ls-5-js" */),
  "component---src-pages-ls-6-js": () => import("./../../../src/pages/ls6.js" /* webpackChunkName: "component---src-pages-ls-6-js" */),
  "component---src-pages-ls-7-js": () => import("./../../../src/pages/ls7.js" /* webpackChunkName: "component---src-pages-ls-7-js" */),
  "component---src-pages-ls-8-js": () => import("./../../../src/pages/ls8.js" /* webpackChunkName: "component---src-pages-ls-8-js" */),
  "component---src-pages-ls-9-js": () => import("./../../../src/pages/ls9.js" /* webpackChunkName: "component---src-pages-ls-9-js" */),
  "component---src-pages-ls-bengali-js": () => import("./../../../src/pages/ls-bengali.js" /* webpackChunkName: "component---src-pages-ls-bengali-js" */),
  "component---src-pages-ls-bhojpuri-js": () => import("./../../../src/pages/ls-bhojpuri.js" /* webpackChunkName: "component---src-pages-ls-bhojpuri-js" */),
  "component---src-pages-ls-fb-js": () => import("./../../../src/pages/ls-fb.js" /* webpackChunkName: "component---src-pages-ls-fb-js" */),
  "component---src-pages-ls-google-js": () => import("./../../../src/pages/ls-google.js" /* webpackChunkName: "component---src-pages-ls-google-js" */),
  "component---src-pages-ls-gujarati-js": () => import("./../../../src/pages/ls-gujarati.js" /* webpackChunkName: "component---src-pages-ls-gujarati-js" */),
  "component---src-pages-ls-hindi-index-js": () => import("./../../../src/pages/ls-hindi/index.js" /* webpackChunkName: "component---src-pages-ls-hindi-index-js" */),
  "component---src-pages-ls-js": () => import("./../../../src/pages/ls.js" /* webpackChunkName: "component---src-pages-ls-js" */),
  "component---src-pages-ls-kannada-js": () => import("./../../../src/pages/ls-kannada.js" /* webpackChunkName: "component---src-pages-ls-kannada-js" */),
  "component---src-pages-ls-marathi-js": () => import("./../../../src/pages/ls-marathi.js" /* webpackChunkName: "component---src-pages-ls-marathi-js" */),
  "component---src-pages-ls-remarketing-js": () => import("./../../../src/pages/ls-remarketing.js" /* webpackChunkName: "component---src-pages-ls-remarketing-js" */),
  "component---src-pages-ls-tamil-js": () => import("./../../../src/pages/ls-tamil.js" /* webpackChunkName: "component---src-pages-ls-tamil-js" */),
  "component---src-pages-marathi-js": () => import("./../../../src/pages/marathi.js" /* webpackChunkName: "component---src-pages-marathi-js" */),
  "component---src-pages-mintegral-js": () => import("./../../../src/pages/mintegral.js" /* webpackChunkName: "component---src-pages-mintegral-js" */),
  "component---src-pages-mujhsekyahopaega-js": () => import("./../../../src/pages/mujhsekyahopaega.js" /* webpackChunkName: "component---src-pages-mujhsekyahopaega-js" */),
  "component---src-pages-phonepe-js": () => import("./../../../src/pages/phonepe.js" /* webpackChunkName: "component---src-pages-phonepe-js" */),
  "component---src-pages-playstore-js": () => import("./../../../src/pages/playstore.js" /* webpackChunkName: "component---src-pages-playstore-js" */),
  "component---src-pages-snlplus-js": () => import("./../../../src/pages/snlplus.js" /* webpackChunkName: "component---src-pages-snlplus-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-trumpcardsmania-js": () => import("./../../../src/pages/trumpcardsmania.js" /* webpackChunkName: "component---src-pages-trumpcardsmania-js" */)
}

